<template>
	<Form ref="form"
		  :label-width="labelWidth"
		  :size="size">
		<div id="searchFilter"
			 style="display: flex; flex-wrap: wrap">
			<slot></slot>
			<FormItem>
				<Button class="search-btn"
						@click="handleQuery">
				</Button>
				<Button icon="el-icon-refresh-right"
						class="refresh-btn"
						@click="handleReset"></Button>
				<Button v-show="collapsiable"
						type="text"
						class="shift-btn"
						@click="shiftCollapsiable">
          <span>
            {{ fold ? '收起' : '展开' }}
            <i :class="fold ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </span>
				</Button>
			</FormItem>
		</div>
	</Form>
</template>
<script>
import {Form, FormItem, Button} from 'element-ui'

export default {
	name: 'SearchFilter',
	components: {Form, FormItem, Button},
	props: {
		// 最大展示数，默认3个，超过则隐藏，为0时不限制
		maxShow: {
			type: Number,
			default: 3,
		},
		labelWidth: {
			type: String,
			default: '100px',
		},
		size: {
			type: String,
			default: 'small',
		},
	},
	data() {
		return {
			collapsiable: false,
			fold: false,
		}
	},
	created() {
	},
	mounted() {
		// 通过最大显示个数控制展开/折叠
		if (this.maxShow > 0) {
			this.minShowCtrol()
		}
	},
	methods: {
		shiftCollapsiable() {
			this.fold = !this.fold
			this.minShowCtrol()
		},
		// 通过maxShow控制元素显示/折叠
		minShowCtrol() {
			const group = window.document.querySelectorAll(`#searchFilter .el-form-item.el-form-item--${this.size}`)
			const len = group?.length ? group?.length - 1 : 0
			if (this.maxShow < len) {
				group.forEach((item, index) => {
					if (index > this.maxShow - 1 && index < len) {
						item.hidden = !this.fold
					}
				})
				this.collapsiable = true
			} else {
				this.collapsiable = false
			}
		},
		handleQuery() {
			this.$emit('search')
		},
		handleReset() {
			this.$emit('reset')
		},
	},
}
</script>
<style lang="scss"
	   scoped>

.search-btn {
	background-image: url('../../../assets/images/sousuo.png');
	background-repeat: no-repeat;
	background-position: center;
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
	float: unset !important;
	border: none;
	padding: 0 !important;
	margin: 0 !important;
	margin-left: 10px !important;
	vertical-align: top;
}

.refresh-btn {
	width: 32px;
	height: 32px;
	border: none;
	color: #117FFC;
	padding: 0;
	background-color: rgba(17, 127, 252, 0.2);
	font-weight: bold;
	font-size: 22px;
	vertical-align: 8px;
	margin-left: 5px;

	&:hover {
		background-color: rgba(17, 127, 252, 0.3);
	}
}

.shift-btn {
	background-repeat: no-repeat;
	background-position: center;
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
	float: unset !important;
	border: none;
	padding: 0 !important;
	margin: 0 !important;
	margin-left: 10px !important;
	vertical-align: top;
}

@media screen and (max-width: 1053px) {
	.search-form {
		padding: 0 !important;
		margin: 0 !important;
	}
}

@media screen and (max-width: 723px) {
	.search-form {
		max-width: 222px;
	}
}
</style>

