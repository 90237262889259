// 维修区分
import { repairAuditStatusEnum } from "@/constant/enum"

export const repairTypeOptions = [
    { id: "1", name: "建议换货", type: "primary" },
    { id: "2", name: "维修", type: "success" },
]

// 保修信息
export const warrantyPeriodOptions = [
    { id: "0", name: "已过保" },
    { id: "1", name: "保修中" },
]

// 服务形式
export const serviceTypeOptions = [
    { id: "1", name: "送修" },
    { id: "2", name: "寄修" },
    { id: "3", name: "现场" },
]

// 维修合同形态
export const repairContractTypeOptions = [
    { id: "1", name: "随时" },
    { id: "2", name: "维修合同" },
    { id: "3", name: "MC" },
]

// 维修作业项目
export const repairItemOptions = [
    { id: "1", name: "定期检查" },
    { id: "2", name: "修理" },
    { id: "3", name: "定期修理" },
    { id: "4", name: "继续作业" },
    { id: "5", name: "更换零件" },
    { id: "6", name: "送货" },
    { id: "7", name: "说明" },
    { id: "8", name: "合同签约" },
    { id: "9", name: "安装机器" },
    { id: "10", name: "搬回修理" },
]

// 故障零件分类
export const faultyPartCategoryOptions = [
    { id: 1, name: "主机" },
    { id: 2, name: "零件" },
]

// 保修期内服务商
export const warrantyServiceOptions = [
    { id: 1, name: "特约维修店" },
    { id: 2, name: "销售服务商" },
]

// 角色类型为[维修审批]时，可以审批的机型数据
export const repairApprovalMachineOptions = [
    { id: 1, name: "打印机" },
    { id: 2, name: "投影仪" },
    { id: 3, name: "办公型设备" },
    { id: 4, name: "生产型打印设备" },
]

// 角色类型为[索赔物流]或[物流审批]时，可以审批的机型数据
export const logisticsMachineOptions = [
    { id: 5, name: "CSPC" },
    { id: 6, name: "SCM" },
]

// 维修申请流程状态
export const repairAuditStatusOptions = [
    { id: repairAuditStatusEnum.SUBMITTED, name: "已提交" },
    { id: repairAuditStatusEnum.REPAIR_APPROVAL_GRANTED, name: "维修审批同意" },
    { id: repairAuditStatusEnum.LOGISTICS_APPROVAL_GRANTED, name: "物流审批同意" },
    { id: repairAuditStatusEnum.OUT_OF_STOCK, name: "缺货等待" },
    { id: repairAuditStatusEnum.SHIPMENT_COMPLETED, name: "发货完成" },
    { id: repairAuditStatusEnum.REPAIR_COMPLETED, name: "维修已完成" },
    { id: repairAuditStatusEnum.EXPENSE_REQUESTED, name: "费用已申请" },
    { id: repairAuditStatusEnum.EXPENSE_APPROVAL_GRANTED, name: "费用审批通过" },
    { id: repairAuditStatusEnum.FOLLOW_UP_AGREED, name: "回访同意" },
    { id: repairAuditStatusEnum.REPAIR_APPROVAL_TERMINATED, name: "维修审批终止" },
    { id: repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED, name: "维修审批退回" },
    { id: repairAuditStatusEnum.LOGISTICS_APPROVAL_RETURNED, name: "物流审批退回" },
    { id: repairAuditStatusEnum.EXPENSE_APPROVAL_RETURNED, name: "费用审批退回" },
    { id: repairAuditStatusEnum.EXPENSE_APPROVAL_REJECTED, name: "费用审批拒绝" },

	{ id: repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED_SUBMIT, name: "已重新提交" },
	{ id: repairAuditStatusEnum.LOGISTICS_APPROVAL_RETURNED_SUBMIT, name: "已重新提交" },
	{ id: repairAuditStatusEnum.COST_APPROVAL_RETURNED_SUBMIT, name: "已重新提交" },
	{ id: repairAuditStatusEnum.COST_APPROVAL_REJECTED_SUBMIT, name: "已重新提交" },
]

// 回访状态
export const followUpStatusOptions = [
    { id: 1, name: "未接听", type: "default" },
    { id: 2, name: "已接听不满意", type: "warning" },
    { id: 3, name: "已接听满意", type: "success" },
]

// 检索状态
export const queryDealFlagOptions = [
	{ id: "1", name: "待处理" },
	{ id: "2", name: "已处理" },
]

// bbs 主题处理状态
export const bbsStatusOptions = [
	{ id: 0, name: "待处理" },
	{ id: 1, name: "已解决" },
	{ id: 2, name: "已关闭" },
	{ id: 3, name: "被警告" },
]

// bbs 问题类型
export const bbsQuesTypeOptions = [
	{ id: 1, name: "纸路结构" },
	{ id: 2, name: "图像质量" },
	{ id: 3, name: "故障代码" },
	{ id: 4, name: "系统软件" },
]

// bbs 问题类型
export const bbsTypeOptions = [
	{ id: 1, name: "问题" },
	{ id: 2, name: "经验" },
]
