<template>
	<div class="page"
		 :style="height">
		<div class="searchbar">
			<div class="action-wrapper pr10">
				<el-button type="primary"
						   size="small"
						   class="table-toolbar-btn"
						   v-if="perms.includes('home:bbs:case:pub')"
						   @click="goToAdd"> 新增
				</el-button>
				<el-button type="primary"
						   size="small"
						   class="table-toolbar-btn"
						   v-if="perms.includes('home:bbs:case:export')"
						   @click="handleExport"> 导出
				</el-button>
			</div>

			<SearchFilter size="mini" :max-show="3" @search="getList(1)" @reset="handleReset">
				<el-form-item label="标题">
					<el-input v-model="query.title"
							  clearable
							  placeholder="请输入标题"></el-input>
				</el-form-item>
				<el-form-item label="发布人">
					<el-input v-model="query.pubBy"
							  clearable
							  placeholder="请输入发布人"></el-input>
				</el-form-item>
				<el-form-item label="所属代理商"
							  prop="agentId">
					<el-select v-model="query.agentId"
							   filterable
							   clearable
							   placeholder="全部"
							   class="search-select_240">
						<el-option v-for="option in agents"
								   :key="option.id"
								   :label="option.name"
								   :value="option.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属网店"
							  prop="netStoreId">
					<el-select v-model="query.netStoreId"
							   clearable
							   placeholder="全部"
							   class="search-select_240">
						<el-option v-for="option in stores"
								   :key="option.id"
								   :label="option.name"
								   :value="option.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="机种分类"
							  prop="deviceNames">
					<el-select v-model="query.deviceNames"
							   filterable
							   clearable
							   allow-create
							   default-first-option
							   placeholder="全部"
							   class="search-select_180">
						<el-option v-for="option in modelTypeOptions"
								   :key="option.id"
								   :label="option.name"
								   :value="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="机型分类"
							  prop="deviceInfo">
					<el-select v-model="query.deviceInfo"
							   clearable
							   placeholder="全部">
						<el-option v-for="option in deviceTypeOptions"
								   :key="option.id"
								   :label="option.name"
								   :value="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="机型名称">
					<el-select v-model="query.deviceIds"
							   multiple
							   filterable
							   clearable
							   allow-create
							   default-first-option
							   placeholder="全部"
							   class="search-select_240">
						<el-option v-for="option in deviceNameOptions"
								   :key="option.id"
								   :label="option.name"
								   :value="option.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="问题类型">
					<el-select v-model="query.quesType"
							   clearable
							   placeholder="全部">
						<el-option v-for="option in bbsQuesTypeOptions()"
								   :key="option.id"
								   :label="option.name"
								   :value="option.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="创建时间">
					<el-date-picker v-model="dateRange"
									value-format="yyyy-MM-dd"
									clearable
									type="daterange"
									start-placeholder="开始日期"
									end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item label="是否精选">
					<el-select v-model="query.goodFlag"
							   clearable
							   placeholder="全部">
						<el-option v-for="option in flagOptions"
								   :key="option.id"
								   :label="option.name"
								   :value="option.id"></el-option>
					</el-select>
				</el-form-item>
			</SearchFilter>
		</div>

		<div class="main-content">
			<el-table :row-class-name="tableRowClassName"
					  :header-cell-style="{color:'#444'}"
					  :data="data"
					  v-loading="loading"
					  class="scroll-table"
					  border
					  @sort-change="handleSortChange"
					  :cell-style="getCellStyle">
				<el-table-column prop="title"
								 label="标题"
								 show-overflow-tooltip
								 align="left"
								 header-align="center"></el-table-column>
				<el-table-column prop="pubTime"
								 label="发布时间"
								 width="180"
								 align="center"
								 header-align="center">
					<template slot-scope="scope">
						{{ scope.row.pubTime ? formatTimestamp(scope.row.pubTime, true) : "" }}
					</template>
				</el-table-column>
				<el-table-column prop="pubBy"
								 label="发布人"
								 width="100"
								 show-overflow-tooltip
								 align="center"
								 header-align="center"></el-table-column>
				<el-table-column prop="createBy"
								 label="用户ID"
								 width="140"
								 show-overflow-tooltip
								 align="center"
								 header-align="center"></el-table-column>
				<el-table-column prop="clickNum"
								 label="阅读量"
								 width="100"
								 align="center"
								 sortable="custom"
								 header-align="center"></el-table-column>
				<el-table-column prop="commentsNum"
								 label="评论量"
								 width="70"
								 align="center"
								 header-align="center"></el-table-column>
				<el-table-column prop="goodFlag"
								 label="精选"
								 width="50"
								 align="center"
								 header-align="center">
					<template slot-scope="scope"> {{ getFlagText(scope.row) }}</template>
				</el-table-column>
				<el-table-column prop="remark"
								 label="备注"
								 align="center"
								 width="150"
								 show-overflow-tooltip
								 header-align="center"></el-table-column>
				<el-table-column label="操作"
								 width="150"
								 align="center"
								 header-align="center"
								 fixed="right">
					<template slot-scope="scope">
						<div class="table-btn-wrapper">
							<el-button
								type="text"
								plain
								class="table-btn info-text"
								@click="goToDetail(scope.row)"
							> 详情
							</el-button>
							<el-button
								type="text"
								plain
								class="table-btn info-text"
								v-if="handleCheckAdmin(scope.row)"
								@click="goToEdit(scope.row)"
							> 编辑
							</el-button>
							<el-button
								type="text"
								plain
								class="table-btn error-text"
								v-if="perms.includes('home:bbs:case:delete')"
								@click="handleDel(scope.row)"
							> 删除
							</el-button>
							<el-button
								type="text"
								plain
								class="table-btn info-text"
								v-if="changeHighlightFlag(scope.row)"
								@click="handleGoodFlag(scope.row)"
							>
								{{ scope.row.goodFlag === 1 ? "取消加精" : "加精" }}
							</el-button>
							<el-button
								type="text"
								plain
								class="table-btn info-text"
								v-if="perms.includes('home:bbs:case:warn')"
								@click="handleWarnFlag(scope.row)"
							>
								{{ scope.row.warnFlag === 1 ? "取消警告" : "警告" }}
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination-wrapper pa25">
				<Pagination :page.sync="query.pageNo"
							:pageSize.sync="query.pageSize"
							:total="total"
							@sizeChange="handleSizeChange"
							@currentChange="handleCurrentChange"></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {bbsQuesTypeOptions, bbsStatusOptions, bbsTypeOptions} from "@/constant/options"
import SearchFilter from "@/views/bbs/components/SearchFilter.vue";

export default {
	components: {
		SearchFilter,
		Pagination,
		bbsQuesTypeOptions,
		bbsTypeOptions,
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			// 查询参数
			query: {
				pubBy:'',
				title: '',
				type: '3', //类型
				pageNo: 1,
				pageSize: 20,
				agentId: '', // 代理商ID
				netStoreId: '',   //网络店ID
				deviceNames: '',
				deviceInfo: '',
				deviceIds: '',
				quesType: '', // 问题类型
				startDate: '',
				endDate: '',
				goodFlag: '', // 是否精选
				sort: '' // 排序字段 asc   desc
			},
			dateRange: '',
			total: 0,
			loading: false,
			// 是否状态选项
			flagOptions: [
				{
					id: 0,
					name: '否'
				},
				{
					id: 1,
					name: '是'
				}
			],
			// 表格数据
			data: [],
			height: '',
			// 代理商
			agents: [],
			// 网络店
			stores: [],
			// 机种分类选项
			modelTypeOptions: [],
			// 机型分类选项
			deviceTypeOptions: [],
			// 机型名称选项
			deviceNameOptions: [],
		}
	},
	created() {
		// 缓存参数
		if (this.$route.params.type) {
			this.query = this.$route.params
		}
		this.getList()
		this.getAgentList()
		this.getModelDevice()

		this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'
	},
	watch: {
		'query.agentId'(val) {
			this.handleStores(val)
		},
		'query.deviceNames'(val) {
			this.query.deviceInfo = ''
			this.query.deviceIds = ''

			let options = []
			for (const model of this.modelTypeOptions) {
				if (model.name === val) {
					options = model.children || []
					break
				}
			}
			this.deviceTypeOptions = options
		},
		'query.deviceInfo'(val) {
			this.query.deviceIds = ''

			let options = []
			for (const device of this.deviceTypeOptions) {
				if (device.name === val) {
					options = device.children || []
					break
				}
			}
			this.deviceNameOptions = options
		},
		dateRange(range) {
			if (!range || range.length !== 2) {
				this.query.startDate = ''
				this.query.endDate = ''
			} else {
				[this.query.startDate, this.query.endDate] = range
			}
		},
	},
	methods: {
		agentId() {
			return this.query.agentId
		},
		deviceNames() {
			return this.query.deviceNames
		},
		deviceInfo() {
			return this.query.deviceInfo
		},
		bbsQuesTypeOptions() {
			return bbsQuesTypeOptions
		},
		formatTimestamp(ms, datetime) {
			const _fmt = datetime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
		// NO.隔行变色
		tableRowClassName({
							  row,
							  rowIndex
						  }) {
			if (rowIndex % 2 == 1) {
				return 'blue'
			} else {
				return 'yellow'
			}
		},
		// 排序规则变更
		handleSortChange({prop, order}) {
			if (order === 'ascending') {
				this.query.sort = 'asc'
			} else if (order === 'descending') {
				this.query.sort = 'desc'
			} else {
				this.query.sort = ''
			}

			this.$nextTick(() => {
				this.getList()
			})
		},
		handleReset() {
			this.query = {
				title: '',
				type: '3', //类型
				pageNo: 1,
				pageSize: 20,
				agentId: '', // 代理商ID
				netStoreId: '',   //网络店ID
				deviceNames: '',
				deviceInfo: '',
				deviceIds: '',
				quesType: '', // 问题类型
				startDate: '',
				endDate: '',
				goodFlag: '', // 是否精选
				sort: '', // 排序字段 asc   desc
			};
			this.dateRange = ''
			this.getList();
		},
		// 获取数据
		getList(page) {
			if (page) this.query.pageNo = page
			this.loading = true
			let that = this
			let data = Object.assign({},that.query, {
				deviceIds: that.query.deviceIds ? that.query.deviceIds.join(',') : '',
			})
			that.$request.post(
				"bbsMainInfoList",
				true, data,
				function (r) {
					if (r.code == "0") {
						that.data = r.data.list
						that.total = r.data.totalCount
					} else {
						that.$message.error(r.msg)
					}
					that.loading = false
				}
			)
		},
		// 代理商
		getAgentList() {
			let that = this
			that.$request.post(
				'bbsListAgent',
				true,
				{},
				function (r) {
					if (r.code == "0") {
						that.agents = r.data
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		// 获取网络店
		handleStores(val) {
			if (val === '') {
				return
			}
			let that = this
			that.$request.post(
				'bbsListStore',
				false,
				{
					agentId: val
				},
				function (r) {
					if (r.code == "0") {
						that.stores = r.data
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		// 获取机种分类
		getModelDevice() {
			let that = this
			that.$request.post(
				"sysModelDevice",
				true, {
					libraryDeviceWithout: "library"
				},
				function (r) {
					if (r.code == "0") {
						that.modelTypeOptions = r.data
					} else {
						that.$message.error(r.msg);
					}
				}
			)
		},
		// 每页大小变化
		handleSizeChange() {
			this.getList()
		},
		// 当前页码变化
		handleCurrentChange() {
			this.getList()
		},
		// Safari 中 table 列同时启用 width 和 show-overflow-tip 时
		// el-tooltip 未正确设置 width 行内样式, Safari 不兼容 col 列设置的宽度
		// 内容过长时, 会导致撑开列宽
		getCellStyle({column}) {
			const tempWidth = column.realWidth || column.width
			if (column.showOverflowTooltip) {
				return {
					minWidth: tempWidth + 'px',
					maxWidth: tempWidth + 'px',
				}
			}
			return {}
		},
		getFlagText(row) {
			for (const option of this.flagOptions) {
				if (option.id === row.goodFlag) {
					return option.name
				}
			}
		},
		// 新增
		goToAdd() {
			this.$router.push({
				name: 'bbsAddEdit',
				query: {
					menuAction: 'caseManagement'
				}
			})
		},
		// 详情
		goToDetail(row) {
			this.$router.push({
				name: 'bbsDetail',
				query: {
					id: row.id,
					menuAction: 'caseManagement'
				},
				params: {
					...this.query
				}
			})
		},
		// 编辑
		goToEdit(row) {
			this.$router.push({
				name: 'bbsAddEdit',
				query: {
					id: row.id,
					menuAction: 'caseManagement'
				},
				params: {
					...this.query
				}
			})
		},
		// 置顶
		handleGoodFlag(row) {
			let title = row.goodFlag === 1 ? '确定要取消精选吗?' : '确定要精选吗?'
			this.$confirm(title, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'bbsMainInfoGood',
					true, {
						id: row.id,
						goodFlag: row.goodFlag === 1 ? 0 : 1 //是否精选 0：否 1：是
					},
					function (r) {
						if (r.code == "0") {
							that.getList()
							that.$message.success('操作成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {
			})
		},
		// 删除
		handleDel(row) {
			this.$confirm('确定要删除吗?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'bbsMainInfoDel',
					true, {
						id: row.id
					},
					function (r) {
						if (r.code == "0") {
							that.getList()
							that.$message.success('删除成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {
			})
		},
		// 警告
		handleWarnFlag(row) {
			let title = row.warnFlag === 1 ? '确定要取消警告吗?' : '确定要警告吗?'
			this.$confirm(title, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					'bbsMainInfoWarn',
					true, {
						id: row.id,
						warnFlag: row.warnFlag === 1 ? 0 : 1
					},
					function (r) {
						if (r.code == "0") {
							that.getList()
							that.$message.success('操作成功')
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			}).catch(() => {
			})
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"bbsExport",
				true,
				that.query,
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click(); //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 加精控制
		changeHighlightFlag(row) {
			// 权限控制
			if (this.perms.includes('home:bbs:case:highlight')) {
				// 状态=== && 待处理 警告===否
				return row.status === 0 && row.warnFlag === 0;
			} else {
				return false
			}
		},
		// 是否是超级管理员和自己创建的
		handleCheckAdmin(val) {
			if (this.perms.includes('home:bbs:case:pub')) {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				const createId = val.createId || '0'
				return userInfo.superAdminFlag === '1' || userInfo.id === createId;
			}
			return false
		},
	}
}

</script>

<style lang="scss"
	   scoped>
.page {
	background-color: white;
}

.search-select_240 {
	min-width: 200px !important;

	::v-deep .el-input, .el-input--small, .el-input--suffix {
		min-width: 200px !important;
	}
}

.search-select_180 {
	min-width: 150px !important;

	::v-deep .el-input, .el-input--small, .el-input--suffix {
		min-width: 150px !important;
	}
}

.searchbar {
	.el-date-editor--daterange.el-input,
	.el-date-editor--daterange.el-input__inner {
		width: 238px;
	}
}

.search-btn {
	float: unset !important;
	border: none;
	padding: 0 !important;
	margin: 0 !important;
	margin-left: 10px !important;
	vertical-align: top;
}

.refresh-btn {
	width: 32px;
	height: 32px;
	border: none;
	color: #117FFC;
	padding: 0;
	background-color: rgba(17, 127, 252, 0.2);
	font-weight: bold;
	font-size: 22px;
	vertical-align: 8px;
	margin-left: 5px;

	&:hover {
		background-color: rgba(17, 127, 252, 0.3);
	}
}

.table-btn-wrapper {
	width: 100%;
	// display: flex;
	// flex-flow: wrap;
	// justify-content: flex-start;

	// .el-button:nth-child(1) {
	// 	margin: auto;
	// }
	// .el-button:nth-child(2n) {
	// 	&+.el-button {
	// 		margin: 0 1px;
	// 	}
	// }
	.table-btn {
		padding: 5px;
		font-weight: bold;
		font-size: 12px;
		border: none;
		margin-left: 0;
		text-decoration: underline;
	}
}

.scroll-table {
	border: 1px solid #dcdfe6;
}

@media screen and (max-width: 1053px) {
	.search-form {
		max-width: 444px;
	}
}

@media screen and (max-width: 723px) {
	.search-form {
		max-width: 222px;
	}
}

</style>
