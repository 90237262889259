// 维修申请流程状态
export const repairAuditStatusEnum = {
	// 0、关单
	CLOSE_ORDER: 0,
    // 1、提交申请 或再次提交
    SUBMITTED: 1,
    // 2、维修审批同意
    REPAIR_APPROVAL_GRANTED: 2,
    // 3、物流审批同意
    LOGISTICS_APPROVAL_GRANTED: 3,
    // 4、缺货等待
    OUT_OF_STOCK: 4,
    // 5、发货完成
    SHIPMENT_COMPLETED: 5,
    // 6、维修已完成
    REPAIR_COMPLETED: 6,
    // 7、费用已申请
    EXPENSE_REQUESTED: 7,
    // 8、费用审批通过
    EXPENSE_APPROVAL_GRANTED: 8,
    // 9、回访同意
    FOLLOW_UP_AGREED: 9,
    // -1、维修审批终止
    REPAIR_APPROVAL_TERMINATED: -1,
    // -2、维修审批退回
    REPAIR_APPROVAL_RETURNED: -2,
    // -3、物流审批退回
    LOGISTICS_APPROVAL_RETURNED: -3,
    // -4、费用审批退回
    EXPENSE_APPROVAL_RETURNED: -4,
    // -5、费用审批拒绝
    EXPENSE_APPROVAL_REJECTED: -5,
	// 21、维修审批退回 _提交
	REPAIR_APPROVAL_RETURNED_SUBMIT: 21,
	// 31、物流审批退回  _提交 - 弃用
	LOGISTICS_APPROVAL_RETURNED_SUBMIT: 31,
	// 41、费用审批退回  _提交
	COST_APPROVAL_RETURNED_SUBMIT: 41,
	// 51、费用审批拒绝 _提交
	COST_APPROVAL_REJECTED_SUBMIT: 51,
}

// 维修审批操作
export const repairActionEnum = {
    // 维修审批同意
    REPAIR_APPROVAL_GRANT: "repair_approval_grant",
    // 维修审批退回
    REPAIR_APPROVAL_RETURN: "repair_approval_return",
    // 维修审批终止
    REPAIR_APPROVAL_TERMINATE: "repair_approval_terminate",

    // 物流审批同意
    LOGISTICS_APPROVAL_GRANT: "logistics_approval_grant",
    // 物流审批退回
    LOGISTICS_APPROVAL_RETURN: "logistics_approval_return",

    // 缺货等待
    OUT_OF_STOCK: "out_of_stock",

    // 费用审批通过
    EXPENSE_APPROVAL_GRANT: "expense_approval_grant",
    // 费用审批退回
    EXPENSE_APPROVAL_RETURN: "expense_approval_return",
    // 费用审批拒绝
    EXPENSE_APPROVAL_REJECT: "expense_approval_reject",
}
